@import url(https://fonts.googleapis.com/css2?family=Arvo&display=swap);
html,
body {
  font-family: 'Arvo', serif;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 60%;

  margin: 0;
  padding: 0;
}

button {
  font-family: 'Arvo', serif;
}

input {
  margin-left: 2px;
  margin-right: 8px;
}

h1 {
  margin: 10px 0;
}

p {
  margin: 25px 0;
}

.container {
  padding: 10px 30px;
  border-radius: 35px;

  background-color: #e0e0e0;

  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
}

.button {
  background-color: #dddddd;
  border: 0;
  border-radius: 20px;
  padding: 1.1rem;
  font-size: 24px;

  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2),
    -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
}

.button:hover {
  box-shadow: none;
}

.button:active {
  box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
    inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
}

